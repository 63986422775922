.background {
  position: relative;
  display: grid;
  height: 100vh;
}

.google_button {
  margin-left: auto;
  padding: 4rem 1rem 1rem 1rem;
}

.datahub_text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
